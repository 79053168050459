<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <p class="describe">筛选</p>
        <div class="segmentation"></div>
        <div class="from_submit">
          <el-form :model="formLabelAlign" class="from">
            <el-form-item label="名称：" label-width="60px" style="width: 310px;">
              <el-input placeholder="请输入名称" size="mini" class="form-inp" v-model="formLabelAlign.name"></el-input>
            </el-form-item>
            <!-- <el-form-item label="请选择部门：" prop="department_id">
              <Bepartment
                ref="Bepartment"
                v-model="formLabelAlign.department_id"
              />
            </el-form-item> -->

            <el-form-item label="类型：" label-width="60px">
              <el-select v-model="formLabelAlign.cate_id" placeholder="请选择">
                <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

            <!-- <el-form-item label="学习周期：">
              <el-select
                v-model="formLabelAlign.check_id"
                placeholder="请选择学习周期"
              >
                <el-option
                  v-for="item in check_times"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="统计周期：">
              <div>
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="formLabelAlign.control_begin"
                  type="date"
                  placeholder="开始日期"
                  @change="DateChange()"
                >
                </el-date-picker>
                至
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="formLabelAlign.control_end"
                  type="date"
                  placeholder="结束日期"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </div>
            </el-form-item> -->
          </el-form>
          <div class="search_button">
            <div>
              <el-button @click="way_tableData(true)" type="primary" icon="el-icon-search">查询</el-button>
              <el-button @click="on_reset" icon="el-icon-refresh-left">重置</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="tab_sty">
        <div class="tab_top">
          <p>
            <span class="span1">一共{{ total }}个宣教</span>
            <span class="span2">已选择 {{ more_List.length }} 个宣教</span>
            <i @click="way_tableData" class="span_i el-icon-refresh">数据更新</i>
          </p>
          <div>
            <el-button type="primary" @click="on_pop_addplus()" icon="el-icon-plus">新建</el-button>
            <!-- <el-button @click="on_pop_flow" icon="el-icon-s-custom"
              >审批</el-button
            > -->
            <!-- <el-button @click="on_poprw_flow" icon="el-icon-tickets"
              >创建任务</el-button
            > -->

            <!-- <el-button icon="el-icon-document-remove" plain>评估</el-button> -->

            <el-button @click="on_deleteList" icon="el-icon-close">删除</el-button>
            <!-- <el-button @click="on_export">导出</el-button> -->
          </div>
        </div>
        <div>
          <el-table highlight-current-row @current-change="way_singleselect" @selection-change="way_moreselect"
            :data="table_List" :header-cell-style="{ background: '#f8f8f9' }" style="flex: 1">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="名称">
              <template slot-scope="scope">
                <el-link class="list_sty" type="primary" @click="on_particulars(scope.row.id)">{{ scope.row.name
                }}</el-link>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="department_name"
              label="所属部门"
            ></el-table-column> -->
            <!-- <el-table-column
              prop="begin_time"
              label="开始时间"
            ></el-table-column>
            <el-table-column prop="end_time" label="结束时间"></el-table-column>
            -->
            <el-table-column prop="type" label="类型"></el-table-column>
            <el-table-column prop="add_time" label="添加时间"></el-table-column>
            <!-- <el-table-column prop="end_time" label="结束时间"></el-table-column> -->
            <!-- <el-table-column
              prop="check_times"
              label="学习周期"
            ></el-table-column>  -->
            <!-- <el-table-column prop="address" label="任务完成">
              <template slot-scope="scope">
                <div class="schedule">{{ scope.row.finish_percent }}</div>
              </template>
            </el-table-column>
            <el-table-column label="当前任务">
              <template slot-scope="scope">
                <div v-if="scope.row.instance">
                  <div
                    class="schedule"
                    v-for="(item, index) in scope.row.instance"
                    :key="index"
                  >
                    <el-tooltip placement="bottom" effect="light">
                      <div slot="content">
                        开始时间:{{ item.begin_time }}<br />结束时间:{{
                          item.end_time
                        }}
                      </div>
                      <p>{{ item.task_name }}</p>
                    </el-tooltip>
                  </div>
                </div>
                <div v-else>未创建任务</div>
              </template>
            </el-table-column> -->

            <!-- <el-table-column prop="last_score" label="扣分"></el-table-column> -->

            <!-- <el-table-column prop="address" label="评估状态">
              <template slot-scope="scope">
                <el-link @click="on_assess(scope.row)" type="primary">{{
                  scope.row.status
                }}</el-link>
              </template>
            </el-table-column> -->
            <!-- <el-table-column label="状态">
              <template slot-scope="scope">
                <el-switch
                  @change="on_is_enable($event, scope.row.id)"
                  v-model="scope.row.is_enable"
                  :active-value="1"
                  :inactive-value="0"
                >
                </el-switch>
              </template>
            </el-table-column> -->

            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-link v-if="scope.row.show_edit == 1" class="list_sty" type="primary"
                  @click="on_pop_addplus(scope.row.id)">修改</el-link>
                <el-link class="list_sty" type="primary" @click="on_particulars(scope.row.id)">详情</el-link>
              </template>
            </el-table-column>
            <el-table-column label="推荐">
              <template slot-scope="scope">
                <el-switch @change="ToUpdateState(scope.row.id, '推荐', TuiSwitchState[scope.$index] == true ? 1 : 0)"
                  v-model="TuiSwitchState[scope.$index]" active-color="#13ce66" inactive-color="#DDDDDD">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column label="热门">
              <template slot-scope="scope">
                <el-switch @change="ToUpdateState(scope.row.id, '热门', HotSwitchState[scope.$index] == true ? 1 : 0)"
                  v-model="HotSwitchState[scope.$index]" active-color="#13ce66" inactive-color="#DDDDDD">
                </el-switch>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination @current-change="way_page" :page-size="page_size" layout="prev, pager, next, jumper"
            :total="total" :current-page="page">
          </el-pagination>
        </div>
      </div>
      <!-- 新建修改弹窗 -->
      <el-dialog :title="title" show-close :visible.sync="dialog_show" center>
        <thoughtredact v-if="dialog_show" ref="thoughtredact" :refbool.sync="dialog_show"
          @way_tableData="way_tableData" />
      </el-dialog>
    </div>
    <el-dialog title="发起审批" show-close :visible.sync="auditpop_show" center>
      <auditpop v-if="auditpop_show" ref="auditpop" :refbool.sync="auditpop_show" flowcode_type="flow_dangerous"
        @way_tableData="way_tableData" />
    </el-dialog>

    <el-dialog title="任务" show-close :visible.sync="renwupop_show" center>
      <renwupop v-if="renwupop_show" ref="renwupop" :refbool.sync="renwupop_show" task_code="eduction_recorder"
        @way_tableData="way_tableData" />
    </el-dialog>
  </div>
</template>

<script>
import table_minin from "../../assets/minin/table_minin";
import auditpop from "../../components/audit_pop";
import renwupop from "../../components/renwu_pop";
import Bepartment from "../../components/Bepartment";
import {
  eductionindex,
  eductiondelete,
  eductionstatusupdate,
  dangerousestimateInfo,
  UpdateState
} from "../../assets/request/api";
export default {
  name: "thought_education",
  mixins: [table_minin],
  components: { Bepartment, auditpop, renwupop },
  data() {
    return {
      SwitchState: true,
      title: "",
      dialog_show: false,
      is_extend: true,
      enable_list: [],
      check_times: [],
      formLabelAlign: {
        name: "",
        cate_id: "",
        is_enable: "",
        full_name: "",
        bengin_time: "",
        end_time: "",
        check_id: "",
        control_begin: "",
        control_end: "",
      },
      //评估操作
      assessrow: "",
      assess_pop: false,
      assess_name: "评估",
      assess_Data: {
        level_type: "",
        level_color: "",
        check_times: "",
        score: "",
        check_type: "",
      },
      level_color_list: [],
      level_list: [],
      check_type: [],
    };
  },
  created() {
    this.url = eductionindex;
    this.is_enableUrl = eductionstatusupdate;
    this.delete_Url = eductiondelete;
    this.fromData = this.formLabelAlign;
    this.xl_attribute = "catesList";
    this.way_tableData();
  },
  methods: {
    ToUpdateState(id, type, state) {
      if (type == '推荐') {
        UpdateState({ id, is_recomend: state }).then(res => {
          console.log(res.data)
        })
      }
      else {
        UpdateState({ id, is_hot: state }).then(res => {
          console.log(res.data)
        })
      }
    },
    way_extend(data) {
      this.enable_list = data.enable_list;
      this.check_times = data.check_times;
      // this.formLabelAlign.control_begin = data.control_time.control_begin;
      // this.formLabelAlign.control_end = data.control_time.control_end;
      this.DateChange(1);

      this.controlDate = [
        // data.control_time.control_begin,
        // data.control_time.control_end,
      ];
    },
    on_pop_addplus(id) {
      this.$router.push({
        path: "/security/thought_modification",
        query: {
          id: id,
        },
      });
    },
    on_particulars(id) {
      this.$router.push({
        path: "/security/thought_particulars",
        query: {
          id: id,
        },
      });
    },

    on_assess(row) {
      this.assessrow = row;
      dangerousestimateInfo({ id: row.id }).then((res) => {
        let { level_color_list, info, level_list, check_type } = res.data;
        this.level_color_list = level_color_list;
        this.level_list = level_list;
        this.check_type = check_type;

        this.assess_Data.level_type = info.level_type;
        this.assess_Data.level_color = info.level_color;
        this.assess_Data.score = info.score;
        this.assess_Data.check_times = info.check_times;
        this.assess_Data.check_type = info.check_type;

        this.assess_pop = true;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}

.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}

.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.form-inp {
  width: 200px;
}

.paging {
  text-align: center;
  padding: 10px;
}

.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  button {
    margin-right: 10px;
  }

  p {
    display: flex;
    align-items: center;
  }
}

.span1 {
  font-size: 14px;
  color: #000000;
}

.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}

.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}

.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}

.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}

.list_sty {
  margin-right: 10px;
}

.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
</style>
