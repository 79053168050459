<template>
  <div class="from_outer">
    <div>
      <el-form ref="formName" label-width="180px" :model="formLabelAlign">
        <div class="time-tys">
          <el-form-item label="开始时间：" prop="begin_time" :rules="[{ required: true, message: '请选择开始时间' }]">
            <div>
              <el-date-picker class="time-inp" value-format="yyyy-MM-dd" v-model="formLabelAlign.begin_time" type="date"
                placeholder="开始时间" @change="DateChange()">
              </el-date-picker>
            </div>
          </el-form-item>
          <p>
            至
          </p>
          <el-form-item label-width="0px" label="" prop="end_time" :rules="[{ required: true, message: '请选择结束时间' }]">
            <div>
              <el-date-picker class="time-inp" value-format="yyyy-MM-dd" v-model="formLabelAlign.end_time" type="date"
                placeholder="结束时间" :picker-options="pickerOptions">
              </el-date-picker>
            </div>
          </el-form-item>
        </div>

        <el-form-item  label="任务表单：" prop="scheme_id"
          :rules="[{ required: true, message: '请选择任务表单' }]">
          <el-select class="form-inp" v-model="formLabelAlign.scheme_id" placeholder="任务表单：">
            <el-option v-for="item in status_list" :key="item.value" :label="item.task_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="完成类型：" prop="finish_type" :rules="[{ required: true, message: '请选择完成类型：' }]">
          <el-select class="form-inp" v-model="formLabelAlign.finish_type" placeholder="完成类型：：">
            <el-option v-for="item in type_list" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item> -->

        <!-- <el-form-item label="任务执行对象：" v-if="task_code != 'inspection_recorder'"  prop="checkedCities" :rules="[{ required: true, message: '请选择执行人员' }]">
          <el-checkbox-group v-model="formLabelAlign.checkedCities">
            <el-checkbox v-for="city in cityOptions" :label="city" :key="city.id">{{ city.name }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item> -->
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
        type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Workschemeindex,
  WorkInstancesave,
  usersearch,
} from "../assets/request/api";
export default {
  name: "audit_pop",
  props: {
    dialog_show: {
      type: Boolean,
    },
    task_code: {
      type: String,
    },
  },
  data() {
    return {
      cityOptions: [],
      checkedCities: [],

      timeData: [],
      type_name: "保存",
      //要修改的id
      id: "",
      status_list: [],
      search_Data: [],
      type_list: [],
      times_list: [],
      formLabelAlign: {
        checkedCities: [],
        scheme_id: "",
        // instance_title: "",
        finish_type: "",
        begin_time: "",
        end_time: "",
        // times_type: "",
        source_ids: "",
        // detail: "",
      },
      //统计周期结束时间的最低选择时间
      pickerOptions: {},
    };
  },
  methods: {
    //失效日期结束日期禁用
    DateChange(type) {
      if (!type) {
        this.formLabelAlign.end_time = "";
      }
      let that = this;
      this.pickerOptions = {
        //日期禁用
        disabledDate(date) {
          return that.$tools.Time_todetermine(
            date,
            that.formLabelAlign.begin_time
          );
        },
      };
    },
    way_show(id) {
      this.formLabelAlign.source_ids = id;
      let code_From = {
        task_code: this.task_code,
      };
      Workschemeindex(code_From).then((res) => {
        Object.assign(this.formLabelAlign, code_From);
        let { data, times_list, type_list, office_position } = res.data;
        this.cityOptions = office_position;
        this.type_list = type_list;
        this.times_list = times_list;
        this.status_list = data;
      });
    },
    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formLabelAlign = JSON.parse(JSON.stringify(this.formLabelAlign));
          formLabelAlign.office_position = formLabelAlign.checkedCities
            .map((item) => item.id)
            .join(",");
          delete formLabelAlign.checkedCities;
          WorkInstancesave(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              that.$emit("way_tableData");
              setTimeout(function () {
                that.$emit("update:refbool", false);
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },

    remoteMethod(query) {
      if (query !== "") {
        usersearch({ truename: query }).then((res) => {
          let { data } = res;
          this.search_Data = data;
          console.log(this.search_Data);
        });
      } else {
        this.search_Data = [];
      }
    },
    //返回
    route_go() {
      this.$emit("update:refbool", false);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.form-inp {
  width: 300px;
}

.pop-inp {
  width: 150px;
}

.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}

.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;

  span {
    color: rgb(45, 140, 240);
  }
}

.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}

.botton-an {
  text-align: center;
  margin-top: 10px;
}

.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}

.time-tys {
  display: flex;

  p {
    margin: 0 5px;
    padding-top: 7px
  }

  .time-inp {
    width: 160px;
  }
}
</style>