<template>
  <div class="from_outer">
    <div>
      <el-form ref="formName" label-width="120px" :model="formLabelAlign">
        <el-form-item label="标题：" prop="name">
          <el-input
            placeholder="请输入标题"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.name"
          ></el-input>
        </el-form-item>

        <el-form-item label="说明：" prop="detail">
          <el-input
            type="textarea"
            placeholder="请输入说明"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.detail"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="模板类型："
          prop="flow_id"
          :rules="[{ required: true, message: '请选择模板类型' }]"
        >
          <el-select
            class="form-inp"
            v-model="formLabelAlign.flow_id"
            placeholder="模板类型："
          >
            <el-option
              v-for="item in status_list"
              :key="item.value"
              :label="item.flowname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { flowindex, flowenginesave } from "../assets/request/api";

export default {
  name: "audit_pop",
  props: {
    dialog_show: {
      type: Boolean
    },
    flowcode_type: {
      type: String
    }
  },
  data() {
    return {
      type_name: "保存",
      //要修改的id
      id: "",
      status_list: [],
      formLabelAlign: {
        danger_id: "",
        name: "",
        detail: "",
        flow_id: ""
      }
    };
  },
  methods: {
    way_show(id) {
      this.formLabelAlign.danger_id = id;
      let froms = {};
      froms.flowcode = this.flowcode_type;
      flowindex(froms).then(res => {
        let { data } = res.data;
        this.status_list = data;
      });
    },
    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let { formLabelAlign } = this;
          flowenginesave(formLabelAlign).then(res => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success"
              });
              let that = this;
              that.$emit("way_tableData");
              setTimeout(function () {
                that.$emit("update:refbool", false);
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$emit("update:refbool", false);
    }
  }
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 300px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}
</style>
